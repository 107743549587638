import React, { useContext } from "react"
import classNames from "classnames"
import { AccordionContext } from "react-bootstrap"
import { useAccordionButton } from "react-bootstrap/AccordionButton"

function AccordionButton({ eventKey, callback, title }) {
  const { activeEventKey } = useContext(AccordionContext)

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  )

  const isCurrentEventKey = activeEventKey === eventKey

  return (
    <button
      type="button"
      className="accordion__button"
      onClick={decoratedOnClick}
    >
      {title}{" "}
      <i
        className={classNames("icon", {
          "icon--minus": isCurrentEventKey,
          "icon--plus": !isCurrentEventKey,
        })}
      />
    </button>
  )
}

export default AccordionButton
