/**
 * External dependencies
 */
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import Button from "../button/button"
import { graphql } from "gatsby"
/**
 * Internal dependencies
 */
 import ContentBlock from "../content/Content";
import "./styles/_index.scss"
import ImageTransform from "../../components/common/ggfx-client/module/components/image-transform"
import { useWindowSize } from "../../hooks/window-size"
const HomeBanner = ({Banner_block}) => {
  let processedImages = JSON.stringify({});
  if (Banner_block?.imagetransforms?.Image_Transforms) {
    processedImages = Banner_block?.imagetransforms?.Image_Transforms;
  }

  const [windowWidth] = useWindowSize()


  return(
  <div className="home-banner">
    <Container className="container-big">
      <Row className="home-banner__inner position-relative d-flex align-items-center justify-content-lg-center g-0">
        <Col className="home-banner__image position-absolute w-100 h-100">
        {windowWidth>1026 &&
        
        <ImageTransform
                                    imagesources={Banner_block?.Image?.url}
                                    renderer={"srcSet"}
                                    imagename={"banner-cta.Image.small"}
                                    attr={{
                                      alt: Banner_block?.Image?.alternativeText
                                        ? Banner_block?.Image?.alternativeText
                                        : "Home contact Strakers",
                                      className: "home-banner-img",
                                    }}
                                    imagetransformresult={processedImages}
                                    id={Banner_block?.id}
                                    // style={{ height: "100%", width: "100%" }}
        ></ImageTransform>
                                  }

        {(windowWidth>768 &&windowWidth<1025)&&
        
        <ImageTransform
                                    imagesources={Banner_block?.Mobile_Image?.url}
                                    renderer={"srcSet"}
                                    imagename={"banner-cta.Image.small"}
                                    attr={{
                                      alt: Banner_block?.Mobile_Image?.alternativeText
                                        ? Banner_block?.Mobile_Image?.alternativeText
                                        : "Home contact Strakers",
                                      className: "home-banner-img",
                                    }}
                                    imagetransformresult={processedImages}
                                    id={Banner_block?.id}
                                    // style={{ height: "100%", width: "100%" }}
        ></ImageTransform>
                                  }

        
         
         {windowWidth<768&&
        <div></div>
        
          }
        </Col>
        <Container className="home-banner__content m-0">
          <Row className="home-banner__entry position-relative d-flex align-items-center g-0">
            <Col>
            <ContentBlock Content={Banner_block?.Title} />

              <div className="home-banner__description">
              <ContentBlock Content={Banner_block?.Content} />
              </div>

              <Button href={Banner_block?.Banner_Cta?.Url?.Alias} color="yellow">
                <Button.Text>{Banner_block?.Banner_Cta?.Label}</Button.Text>
              </Button>
            </Col>
            <Col xs="auto">
              <h2 className="h1-like d-none d-lg-block mb-lg-0">
                {Banner_block?.Big_Title}
              </h2>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  </div>
)
        }

export default HomeBanner

export const bannerCtaBlockFragment = graphql`
    fragment BannerCtaBlockFragment on GLSTRAPI_ComponentComponentBannerCtaBlock {
        id
        Banner_block {
          id
          imagetransforms
          Big_Title
          Content
          Publish
          Mobile_Image {
            url
            alternativeText
          }
          Banner_Cta {
            Label
            Link
            Url {
              Alias
            }
          }
          Image {
            alternativeText
            url
          }
          Title
        }

    }
`