import React from "react"
import { Accordion } from "react-bootstrap"

/**
 * Internal dependencies.
 */
import AccordionItem from "./accordion-item"

import "./styles/_index.scss"

const Accordions = ({ children }) => (
  <Accordion className="accordions">{children}</Accordion>
)
Accordions.Item = AccordionItem

export default Accordions
