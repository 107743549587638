import React from "react"
import { Accordion } from "react-bootstrap"
import AccordionButton from "./accordion-button"

const AccordionItem = ({ children, eventKey, title }) => (
  <Accordion.Item eventKey={eventKey}>
    <AccordionButton eventKey={eventKey} title={title} />
    <Accordion.Body>{children}</Accordion.Body>
  </Accordion.Item>
)

export default AccordionItem
